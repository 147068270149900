@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

li[data-list="bullet"] {
  list-style-type: disc;
  margin-left: 20px;
}

li[data-list="ordered"] {
  list-style-type: decimal;
  margin-left: 20px;
}

/* Jodit Editor css*/
.jodit-wysiwyg ul,
.jodit-wysiwyg ol {
  margin: 0;
  padding-left: 1.5rem; /* Adjust padding as needed */
  list-style-position: inside;
}

/* Jodit Editor css*/

/* Scrollbar css */
* {
  scrollbar-width: thin;
}

/* mobile number css*/
.react-tel-input .form-control {
  width: 100% !important;
  height: 37px !important;
  border: 1px solid #e5e7eb !important;
}
.react-tel-input .flag-dropdown {
  /* background: #ffffff !important; */
  border: 1px solid #e5e7eb !important;
}

/* elipse classes*/
.ellipsis {
  width: 12rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.employee-ellipsis {
  width: 10rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.product-ellipsis {
  width: 10rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Gantt chart css*/

/* ._2B2zv {
  overflow: auto !important;
} */
/* Gantt chart css*/
.nameTn {
  width: 10rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.widthAssign {
  width: 6rem;
}
.wh-17 {
  width: 5rem;
  height: 5rem;
}

.scrollbargantt {
  width: 1200px;
  padding: 0 0 10px;
}
