/*Landing Page*/

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
@import url("https://fonts.googleapis.com/css2?family=Yaldevi:wght@200..700&display=swap");

.para-box::after {
  content: url("../public/assets/slider.png");
  position: absolute;
  left: 0;
  top: 0;
}

.para-box::before {
  content: url("../public/assets/one.png");
  position: absolute;
  right: 0;
  bottom: 0;
}
.deatils {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
/* @media screen and (min-width:1200px) and (max-width:1400) {
  .container {
    display: none !important;
  }
  
} */
@media screen and (min-width: 1100px) and (max-width: 1400px) {
  .container {
    max-width: 1024px !important;
  }
}
@media screen and (min-width: 992px) and (max-width: 1027px) {
  .container {
    max-width: 800px !important;
  }
}
/*Landing Page*/

/* filter section*/
#dialog-right {
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}
/* filter section*/

/* for chrome */

/* default apperance of number input  */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
/* default apperance of date input  */
input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

/* for firefox */

/* default apperance of number input  */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

/* default apperance of date input  */

input[type="date"]::-moz-calendar-picker-indicator {
  -moz-appearance: none; /* Firefox */
  appearance: none;
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
.wb-des {
  /* word-wrap: break-word;
  overflow-wrap: break-word; */
  word-break: break-word;
  /* word-break: break-all; */
}

/*React Flow chart css*/
.react-flow__panel {
  display: none;
}
/*React Flow chart css*/
